//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import { getMarketplacesListArray } from "@/utils/enums";
export default {
  mixins: [ModalMixin],
  data() {
    const select_marketplace_items = [...getMarketplacesListArray()];
    const initial_new_connection = {
      id: "",
      name: "",
      datasource: select_marketplace_items[0],
      apikey: ""
    };
    return {
      initial_new_connection,
      new_connection: {
        ...initial_new_connection
      },
      loading: false,
      onComplete: null
    };
  },
  mounted() {},
  methods: {
    onOpen({
      item,
      onComplete
    }) {
      this.new_connection = {
        ...item
      };
      this.onComplete = onComplete;
    },
    async submit({
      close
    }) {
      this.loading = true;
      try {
        const result = await this.$store.dispatch("reviewManagement/updateToken", {
          id: this.new_connection.id,
          name: this.new_connection.name,
          datasource: this.new_connection.datasource,
          apikey: this.new_connection.apikey,
          user_id: this.new_connection.user_id
        });
        this.loading = false;
        close();
        this.onComplete && this.onComplete(result);
        this.$emit("complete", result);
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    valid() {
      var _this$new_connection$;
      return (_this$new_connection$ = this.new_connection.name) === null || _this$new_connection$ === void 0 ? void 0 : _this$new_connection$.length;
    },
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Сохранить изменения",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};