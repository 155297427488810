//
//
//
//
//
//
//
//
//

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      validator(value) {
        return ["green", "red"].includes(value);
      },
      required: true
    }
  }
};